import {
  HavahUnityGameContainer,
  useRegisterEvent,
} from '@zenaad/react-havah-unity-game';
import { CSSProperties } from 'react';

const App = () => {
  // ---------------------------------------------
  // register event
  // ---------------------------------------------
  useRegisterEvent();

  const style: CSSProperties = {
    width: '100vw',
    height: '100vh',
  };

  return <HavahUnityGameContainer style={style} loading />;
};

export default App;
