import './index.css';

import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { HavahUnityGameProvider } from '@zenaad/react-havah-unity-game';
import { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';

import App from './app/app';
import reportWebVitals from './reportWebVitals';

const config = {
  loaderUrl:
    'https://stage-metamatch-client.zenaplay.com//Build/MetaMatch.loader.js',
  dataUrl:
    'https://stage-metamatch-client.zenaplay.com/Build/MetaMatch.data.unityweb',
  frameworkUrl:
    'https://stage-metamatch-client.zenaplay.com/Build/MetaMatch.framework.js.unityweb',
  codeUrl:
    'https://stage-metamatch-client.zenaplay.com//Build/MetaMatch.wasm.unityweb',
  streamingAssetsUrl:
    'https://stage-metamatch-client.zenaplay.com/StreamingAssets',
  productName: 'MetaMatch',
  productVersion: '0.0.1',
  companyName: 'ZENAAD',
};

// const config = {
//   loaderUrl: 'MetaMatch_local/Build/MetaMatch.loader.js',
//   dataUrl: 'MetaMatch_local/Build/MetaMatch.data.unityweb',
//   frameworkUrl: 'MetaMatch_local/Build/MetaMatch.framework.js.unityweb',
//   codeUrl: 'MetaMatch_local/Build/MetaMatch.wasm.unityweb',
//   streamingAssetsUrl: '/MetaMatch_local/StreamingAssets',
//   productName: 'MetaMatch',
//   productVersion: '0.0.1',
//   companyName: 'ZENAAD',
// };

const gameObjectName = 'WalletManager';

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <StrictMode>
    <QueryClientProvider client={queryClient}>
      <HavahUnityGameProvider config={config} gameObjectName={gameObjectName}>
        <App />
      </HavahUnityGameProvider>
    </QueryClientProvider>
  </StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
